import {getPathPrefix, getRecord, getRecords} from "@/services/api";

export function getShop(id, filterQuery = {}, options = {}) {
    return getRecord('/prologistics/web/api/v1/' + getPathPrefix() + 'shops/' + id + '.json', filterQuery, options)
}

export function getSelectableShops(filterQuery, options = {}) {
    filterQuery.selectable = true
    return getRecords('/prologistics/web/api/v1/' + getPathPrefix() + 'shops.json', filterQuery, options)
}

export function getSelectableRoles(filterQuery, options = {}) {
    filterQuery.selectable = true
    return getRecords('/prologistics/web/api/v1/' + getPathPrefix() + 'shops/roles.json', filterQuery, options)
}