<template>
  <div class="opening-hours">
    <ul class="list-group">
      <li class="list-group-item" v-for="(oh_day, key) in valueOut">
        <b-row align-v="center">
          <b-col :cols="4">
            {{ $t('common.' + key) }}
          </b-col>
          <b-col :cols="8">
            <b-row v-if="oh_day.length===0" class="justify-content-center">
              {{ $t('common.closed') }}
            </b-row>
            <b-row v-else v-for="(interval,i) in oh_day" class="justify-content-center">
              <b-col v-for="(date,i_interval) in interval">
                <generic-input type="timepicker" v-model="interval[i_interval]"
                               :label="i_interval===0 ? 'common.since' : 'common.until'" :disabled="disabled" required/>
              </b-col>
              <b-col :cols="1">
                <b-button v-if="!$compute(disabled)" @click.prevent="$delete(oh_day, i)" :disabled="disabled" size="xs"
                          variant="no-outline"
                          style="float: right;">
                  <font-awesome-icon icon="times"/>
                </b-button>
              </b-col>
            </b-row>
            <b-row v-if="!$compute(disabled) && oh_day.length < 2" class="justify-content-center">
              <b-button variant="default" size="xs"
                        @click.prevent="oh_day.push(timeIntervalDefaultState())">
                {{ $t('opening_hours.add_time_interval') }}
              </b-button>
            </b-row>
          </b-col>
        </b-row>
      </li>
    </ul>
  </div>
</template>

<script>
import GenericInput from "@/components/generic_input";

export default {
  name: "OpeningHours",
  components: {GenericInput},
  props: {
    value: {
      type: Object,
      default: () => {
        return {}
      }
    },
    disabled: {
      type: [Boolean, Function],
      default: false
    },
  },
  data() {
    return {
      valueOut: {}
    }
  },
  watch: {
    value: {
      immediate: true,
      async handler(value) {
        this.valueOut = this.weekdays().reduce((prev, curr) => {
          prev[curr] = value[curr] || []
          return prev
        }, {})
      }
    },
    valueOut: {
      immediate: false,
      deep: true,
      async handler(value) {
        if (JSON.stringify(value) !== JSON.stringify(this.value)) {
          this.$emit('input', value)
        }
      }
    }
  },
  computed: {},
  methods: {
    weekdays() {
      return ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']
    },
    timeIntervalDefaultState() {
      return [new Date("Thu, 01 Jan 1970 08:00:00"), new Date("Thu, 01 Jan 1970 18:00:00")]
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
