<template>
  <div class="reservation-modal">
    <b-modal v-model="visible" v-if="visible" v-on="$listeners" size="lg" hide-header-close
             header-class="justify-content-center" @ok.prevent="submitModal">

      <template #modal-title>
        <h1>{{ $t(title || 'transfers.add') }}</h1>
      </template>

      <template #default="{ ok }">
        <b-form ref="form" @submit.prevent="ok">
          <!-- shops -->
          <ul class="list-group m-t-md"
              v-for="(list, shopKey) in { start: startShopInputList, end: endShopInputList }">
            <li class="list-group-item">
              <b-row align-v="center" @click.prevent="$data[shopKey+'_shopVisible'] = !$data[shopKey+'_shopVisible']"
                     style="cursor: pointer;">
                <b-col>
                  <h4>{{ $t("common." + shopKey + "_city") }}</h4>
                </b-col>
                <b-col>
                  <b-button class="float-right" variant="default">
                    <font-awesome-icon :class="{'fa-rotate-180': $data[shopKey+'_shopVisible']}" icon="angle-down"/>
                  </b-button>
                </b-col>
              </b-row>
            </li>
            <li v-if="!$data[shopKey + '_shopVisible']" class="list-group-item">
          <span @click.prevent="$data[shopKey+'_shopVisible'] = !$data[shopKey+'_shopVisible']"
                style="cursor: pointer;">
            {{ record.transfer[shopKey + '_shop'].name }}<br v-if="record.transfer[shopKey+'_shop'].name">
            {{ record.transfer[shopKey + '_shop'].street_name }}<br>
            {{ record.transfer[shopKey + '_shop'].postcode }}, {{ record.transfer[shopKey + '_shop'].city.name }}
          </span>
            </li>
            <li v-show="$data[shopKey + '_shopVisible']" class="list-group-item"
                v-if="list.find(row => row.find(input => input.visible===undefined || $compute(input.visible)))">
              <b-row align-v="center" v-for="row in list">
                <b-col :cols="$compute(input.cols)" v-for="input in row"
                       v-if="input.visible===undefined || $compute(input.visible)">
                  <generic-input v-bind="input" :value="$g(record.transfer[shopKey+'_shop'], input.key)"
                                 @input="(event) => $s(record.transfer[shopKey+'_shop'], input.key, event)"/>
                </b-col>
              </b-row>
            </li>
            <li v-show="$data[shopKey + '_shopVisible']" class="list-group-item">
              <b-row align-v="center"
                     @click.prevent="$data[shopKey+'_shopTimeVisible'] = !$data[shopKey+'_shopTimeVisible']"
                     style="cursor: pointer;">
                <b-col>
                  {{ $t('common.opening_hours') }}*
                </b-col>
                <b-col>
                  <b-button class="float-right" variant="default">
                    <font-awesome-icon :class="{'fa-rotate-180': $data[shopKey+'_shopTimeVisible']}" icon="angle-down"/>
                  </b-button>
                </b-col>
              </b-row>
              <b-row v-if="$data[shopKey+'_shopTimeVisible']">
                <b-col class="m-t-sm">
                  <opening-hours v-model="record.transfer[shopKey + '_shop'].opening_hours"/>
                </b-col>
              </b-row>
            </li>
            <li v-show="$data[shopKey + '_shopVisible']" class="list-group-item">
              <metadata-edit-list v-model="record.transfer[shopKey+'_shop'].metadata"
                                  label="multiples.metadata.one"/>
            </li>
          </ul>

          <!-- general -->
          <ul class="list-group m-t-md">
            <li class="list-group-item">
              <h4>{{ $t("common.general") }}</h4>
            </li>
            <li class="list-group-item" v-for="list in generalInputList"
                v-if="list.find(input => input.visible===undefined || $compute(input.visible))">
              <b-row align-v="center">
                <b-col v-for="input in list" v-bind="$compute(input.col)" v-if="input.visible===undefined || $compute(input.visible)">
                  <generic-input v-bind="input" :value="$g(record, input.key)"
                                 @input="(event) => $s(record, input.key, event)" @onSelected="input.onSelected || (()=>{})"/>
                </b-col>
              </b-row>
            </li>
            <li class="list-group-item">
              <metadata-edit-list v-model="record.transfer.metadata" label="multiples.metadata.one"/>
            </li>
          </ul>
        </b-form>
      </template>

      <template #modal-footer="{ cancel }">
        <div class="float-right">
          <b-button size="sm" variant="default" @click="cancel">{{ $t('button.back') }}</b-button>
          <b-button size="sm" variant="primary" type="submit" @click.prevent="$refs.form.requestSubmit()">
            {{ $t('button.save') }}
          </b-button>
        </div>
      </template>

    </b-modal>
  </div>
</template>

<script>
import {getReservation, putReservation} from "@/services/reservations";
import {getSelectableRoles} from "@/services/shops";
import {
  getSelectableEngines, getSelectableRims, getSelectableTires,
  getSelectableTransmissions, getSelectableTreads,
} from "@/services/vehicles";
import SearchableSelect from "@/components/searchable_select";
import Datepicker from "@/components/datepicker";
import GenericInput from "@/components/generic_input";
import {mapGetters} from "vuex";
import MetadataEditList from "@/components/metadata_edit_list";
import OpeningHours from "@/components/opening_hours";
import {getSelectableClients} from "@/services/clients";

export default {
  name: "ReservationModal",
  components: {OpeningHours, MetadataEditList, GenericInput, Datepicker, SearchableSelect},
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      default: -1,
    },
    type: {
      type: String,
      default: 'create'
    },
    title: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      visible: false,
      start_shopVisible: false,
      end_shopVisible: false,
      start_shopTimeVisible: false,
      end_shopTimeVisible: false,
      record: this.recordDefaultState(),
      selectableCache: {
        shopRoles: [],
        rentalClients: [],
        vehicleEngines: [],
        vehicleTransmissions: [],
        vehicleTires: [],
        vehicleRims: [],
        vehicleTreads: []
      },
      startShopInputList: this.shopConfigList('start_shop'),
      endShopInputList: this.shopConfigList('end_shop'),
      generalInputList: [
        [
          {
            key: 'transfer.deputy_client_id',
            label: 'multiples.principal.one',
            placeholder: 'selector.select_principal',
            type: 'select',
            fetchRecords: () => this.selectableCache.rentalClients,
            onSelected: () => null,
            disabled: () => !this.checkAllowedTypes(['create']),
            visible: () => this.record.transfer.deputy_client_id > 0,
            required: true
          }
        ],
        [
          {
            key: 'pickup_date',
            label: ['common.pickup_date_day', 'common.pickup_date_time'],
            type: 'datepicker',
            onSelected: () => null,
            disabled: () => !this.checkAllowedTypes(['create', 'edit']),
            required: true
          },
          {
            key: 'delivery_date',
            label: ['common.delivery_date_day', 'common.delivery_date_time'],
            type: 'datepicker',
            onSelected: () => null,
            disabled: () => !this.checkAllowedTypes(['create', 'edit', 'restore']),
            required: true
          }
        ],
        [
          {
            key: 'transfer.vehicle_class.name',
            label: 'multiples.vehicle_class.one',
            onSelected: () => null,
            disabled: () => !this.checkAllowedTypes(['create']),
            required: true
          }
        ],
        [
          {
            key: 'transfer.vehicle.brand',
            label: 'common.brand',
            onSelected: () => null,
            disabled: () => !this.checkAllowedTypes(['create']),
            required: true
          },
          {
            key: 'transfer.vehicle.model',
            label: 'common.model',
            placeholder: 'RS e-tron GT',
            onSelected: () => null,
            disabled: () => !this.checkAllowedTypes(['create']),
            required: true
          },
        ],
        [
          {
            key: 'transfer.engine',
            label: 'common.engine',
            placeholder: 'selector.select_engine',
            type: 'select',
            fetchRecords: () => this.selectableCache.vehicleEngines,
            onSelected: () => null,
            required: true
          },
          {
            key: 'transfer.transmission',
            label: 'common.transmission',
            placeholder: 'selector.select_transmission',
            type: 'select',
            fetchRecords: () => this.selectableCache.vehicleTransmissions,
            onSelected: () => null,
            required: true
          }
        ],
        [
          {
            key: 'transfer.tire',
            label: 'common.tire',
            placeholder: 'selector.select_tire',
            type: 'select',
            fetchRecords: () => this.selectableCache.vehicleTires,
            onSelected: () => null,
            required: true
          },
          {
            key: 'transfer.rim',
            label: 'common.rim',
            placeholder: 'selector.select_rim',
            type: 'select',
            fetchRecords: () => this.selectableCache.vehicleRims,
            onSelected: () => null,
            required: true
          }
        ],
        [
          {
            key: 'transfer.tread',
            label: 'common.tread',
            placeholder: 'selector.select_tread',
            type: 'select',
            fetchRecords: () => this.selectableCache.vehicleTreads,
            onSelected: () => null,
            required: true
          },
          {
            key: 'transfer.tread_mm',
            label: 'common.tread_mm',
            type: 'number',
            precision: 1,
            min: 0,
            onSelected: () => null,
            required: true
          }
        ],
        [
          {
            key: 'transfer.vin',
            label: 'common.vin',
            placeholder: 'ZFAEFAC39MX018432',
            onSelected: () => null
          },
          {
            key: 'transfer.license_plate',
            label: 'common.license_plate',
            placeholder: 'AAA-BB 123',
            onSelected: () => null
          }
        ],
        [
          {
            key: 'transfer.delivery_in_opening_hours',
            label: 'common.delivery_in_opening_hours',
            type: 'checkbox',
            onSelected: () => null
          },
          {
            key: 'transfer.requires_license_plate',
            label: 'common.requires_license_plate',
            type: 'checkbox',
            onSelected: () => null
          }
        ],
        [
          {
            key: 'notes',
            label: 'common.notes',
            placeholder: 'transfers.additional_notes',
            onSelected: () => null
          },
          {
            key: 'price',
            label: 'common.price',
            type: 'currency',
            col: { cols: 4 },
            onSelected: () => null
          }
        ],
        [
          {
            key: 'transfer.external_order_number',
            label: 'common.order_number',
            placeholder: 'ORDER XXX',
            onSelected: () => null
          },
          {
            key: 'transfer.external_invoice_number',
            label: 'common.invoice_number',
            placeholder: 'INVOICE XXX',
            onSelected: () => null
          },
        ]
      ]
    }
  },
  watch: {
    value: {
      immediate: true,
      async handler() {
        if (this.value) {
          this.selectableCache.shopRoles = await getSelectableRoles({})
          this.selectableCache.rentalClients = await getSelectableClients({option: 'rental'})
          this.selectableCache.vehicleEngines = await getSelectableEngines({})
          this.selectableCache.vehicleTransmissions = await getSelectableTransmissions({})
          this.selectableCache.vehicleTires = await getSelectableTires({})
          this.selectableCache.vehicleRims = await getSelectableRims({})
          this.selectableCache.vehicleTreads = await getSelectableTreads({})
          if (this.id > 0) this.record = await getReservation(this.id) // TODO: catch
          else if (this.record.id > 0) this.record = this.recordDefaultState()
          this.start_shopVisible = false
          this.end_shopVisible = false
          this.start_shopTimeVisible = false
          this.end_shopTimeVisible = false
        }
        this.visible = this.value
      }
    },
    visible: {
      immediate: true,
      handler() {
        this.$emit('input', this.visible)
      }
    }
  },
  computed: {
    ...mapGetters("user", ["current_user"])
  },
  methods: {
    shopConfigList(shopKey) {
      return [
        [
          {
            key: 'street_name',
            label: 'common.street_name',
            placeholder: this.$t('prologistics.street_name'),
            col: { cols: 8 },
            disabled: () => true,
            required: true
          },
          {
            key: 'postcode',
            label: 'common.postcode',
            placeholder: this.$t('prologistics.postcode'),
            col: { cols: 4 },
            disabled: () => true,
            required: true
          }
        ],
        [
          {
            key: 'town_name',
            label: 'common.town',
            placeholder: this.$t('prologistics.town_name'),
            disabled: () => true,
          },
          {
            key: 'city.name',
            label: 'common.city',
            placeholder: this.$t('prologistics.city_name'),
            disabled: () => true,
            required: true
          },
          {
            key: 'city.country_name',
            label: 'common.country',
            placeholder: this.$t('prologistics.country_name'),
            disabled: () => true,
            required: true
          }
        ],
        [
          {
            key: 'contact.name',
            label: 'common.name',
            placeholder: 'Max Mustermann',
            col: { cols: 4 },
            disabled: () => true
          }
        ],
        [
          {
            key: 'contact.email',
            label: 'common.email',
            placeholder: this.$t('prologistics.email')
          },
          {
            key: 'contact.phone_number',
            label: 'common.phone_number',
            placeholder: "+49123456789"
          }
        ],
        [
          {
            key: 'role',
            label: 'common.role',
            col: { cols: 4 },
            type: 'select',
            fetchRecords: () => this.selectableCache.shopRoles,
            required: true
          }
        ],
        [
          {
            key: 'contact.id_last_four',
            label: 'common.id_last_four',
            placeholder: 'HKS2',
            visible: () => this.record.transfer[shopKey].role === 3
          },
          {
            key: 'contact.license_last_four',
            label: 'common.license_last_four',
            placeholder: 'MDS9',
            visible: () => this.record.transfer[shopKey].role === 3
          },
          {
            key: 'contact.customer_number',
            label: 'common.customer_number',
            placeholder: 'VEH-12345',
            visible: () => this.record.transfer[shopKey].role === 3
          },
        ],
        [
          {
            key: 'authorizer.name',
            label: 'common.authorizer_name',
            placeholder: 'John Doe',
            col: { cols: 4 },
            visible: () => this.record.transfer[shopKey].role === 3
          },
        ],
        [
          {
            key: 'authorizer.id_last_four',
            label: 'common.authorizer_id_last_four',
            placeholder: 'KDS3',
            visible: () => this.record.transfer[shopKey].role === 3
          },
          {
            key: 'authorizer.license_last_four',
            label: 'common.authorizer_license_last_four',
            placeholder: 'MGSD8',
            visible: () => this.record.transfer[shopKey].role === 3
          },
        ],
        [
          {
            key: 'authorizer.email',
            label: 'common.authorizer_email',
            placeholder: this.$t('prologistics.email'),
            visible: () => this.record.transfer[shopKey].role === 3
          },
          {
            key: 'authorizer.phone_number',
            label: 'common.authorizer_phone_number',
            placeholder: "+49123456789",
            visible: () => this.record.transfer[shopKey].role === 3
          }
        ]
      ]
    },
    recordDefaultState() {
      return {
        id: 0,
        driver_id: 0,
        pickup_date: new Date(),
        delivery_date: new Date(),
        notes: null,
        transfer: {
          id: 0,
          deputy_client_id: 0,
          start_shop: this.shopDefaultState(),
          end_shop: this.shopDefaultState(),
          vehicle_class_id: 0,
          engine: -1,
          transmission: 0,
          tire: 0,
          rim: 0,
          tread: 0,
          tread_mm: 0.0,
          delivery_in_opening_hours: false,
          requires_license_plate: false,
          external_order_number: null,
          external_invoice_number: null,
          license_plate: null,
          vin: null,
          vehicle: {
            brand: null,
            model: null
          },
          metadata: []
        }
      }
    },
    shopDefaultState() {
      return {
        id: 0,
        street_name: null,
        postcode: null,
        town_name: null,
        contact: {
          name: null,
          id_last_four: null,
          license_last_four: null,
          phone_number: null,
          email: null,
          customer_number: null
        },
        authorizer: {
          name: null,
          id_last_four: null,
          license_last_four: null,
          phone_number: null,
          email: null
        },
        city: {
          name: '',
          country_name: 'Deutschland'
        },
        role: 0,
        metadata: [],
        opening_hours: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'].reduce((prev, curr) => {
          prev[curr] = [[new Date("Thu, 01 Jan 1970 08:00:00"), new Date("Thu, 01 Jan 1970 18:00:00")]]
          return prev
        }, {})
      }
    },
    checkAllowedTypes(types) {
      return types.includes(this.type)
    },
    async submitModal() {
      putReservation(this.id, this.record).then((response) => {
        this.record = this.recordDefaultState()
        this.visible = false
      })
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
