import {getPathPrefix, getRecords} from "@/services/api";

export function getSelectableVehicles(filterQuery, options = {}) {
    filterQuery.selectable = true
    return getRecords('/prologistics/web/api/v1/' + getPathPrefix() + 'vehicles.json', filterQuery, options)
}

export function getSelectableBrands(filterQuery, options = {}) {
    filterQuery.selectable = true
    return getRecords('/prologistics/web/api/v1/' + getPathPrefix() + 'vehicles/brands.json', filterQuery, options)
}

export function getSelectableEngines(filterQuery, options = {}) {
    filterQuery.selectable = true
    return getRecords('/prologistics/web/api/v1/' + getPathPrefix() + 'vehicles/engines.json', filterQuery, options)
}

export function getSelectableTransmissions(filterQuery, options = {}) {
    filterQuery.selectable = true
    return getRecords('/prologistics/web/api/v1/' + getPathPrefix() + 'vehicles/transmissions.json', filterQuery, options)
}

export function getSelectableTires(filterQuery, options = {}) {
    filterQuery.selectable = true
    return getRecords('/prologistics/web/api/v1/' + getPathPrefix() + 'vehicles/tires.json', filterQuery, options)
}

export function getSelectableRims(filterQuery, options = {}) {
    filterQuery.selectable = true
    return getRecords('/prologistics/web/api/v1/' + getPathPrefix() + 'vehicles/rims.json', filterQuery, options)
}

export function getSelectableTreads(filterQuery, options = {}) {
    filterQuery.selectable = true
    return getRecords('/prologistics/web/api/v1/' + getPathPrefix() + 'vehicles/treads.json', filterQuery, options)
}